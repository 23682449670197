import { createApp } from "vue"
import App from "./App.vue"
/* import router from "./router"; */
import store from "./store"
import InlineSvg from "vue-inline-svg"
import Vue3Resize from "vue3-resize"

createApp(App)
  .use(Vue3Resize)
  .use(store)
  .component("inline-svg", InlineSvg)
  /* .use(router) */
  .mount("#app")
