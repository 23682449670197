export default {
  sky1: {
    preload: 0,
    start: -0.5,
    easeInLength: 0.5,
    easeOutLength: 1.5,
    end: 9.5,
    contentLength: 2
  },

  slide0: {
    preload: 0,
    start: -1,
    end: 1
  },
  slide1: {
    preload: 0,
    start: 0,
    end: 2
  },
  slide2: {
    preload: 0,
    start: 1,
    end: 3,
    contentLength: 1
  },
  horizontalLinePattern: {
    preload: 0,
    start: 1.5,
    easeInLength: 0.5,
    easeOutLength: 1.5,
    end: 4,
    contentLength: 1
  },
  slide3: {
    preload: 1,
    start: 2,
    end: 4
  },

  horizontalDotPattern: {
    preload: 0,
    start: 3,
    easeInLength: 0.5,
    easeOutLength: 0.5,
    end: 5,
    contentLength: 1
  },
  slide4: {
    preload: 2,
    start: 3,
    end: 5
  },
  line1: {
    preload: 2,
    start: 3,
    end: 5.5
  },
  accelerationLines4: {
    preload: 1,
    start: 3.2,
    end: 6
  },
  slide5: {
    preload: 3,
    start: 4,
    end: 6
  },
  slide6: {
    preload: 4,
    start: 5,
    end: 7
  },
  slide7: {
    preload: 5,
    start: 6,
    end: 8
  },
  line2: {
    preload: 5,
    start: 6.5,
    end: 9
  },
  checkerBoardPattern: {
    preload: 5,
    start: 6,
    easeInLength: 1,
    easeOutLength: 1,
    end: 26,
    contentLength: 6
  },
  slide8: {
    preload: 6,
    start: 7,
    end: 9
  },
  slide9: {
    preload: 7,
    start: 8,
    end: 10
  },
  slide10: {
    preload: 8,
    start: 9,
    end: 11
  },
  line3: {
    preload: 8,
    start: 9.5,
    end: 12.5
  },
  slide11: {
    preload: 9,
    start: 10,
    end: 12
  },
  slide12: {
    preload: 10,
    start: 11,
    end: 13
  },
  verticalLines12: {
    preload: 10,
    start: 11,
    end: 13
  },

  line4: {
    preload: 11,
    start: 11,
    end: 17
  },
  slide13: {
    preload: 11,
    start: 12,
    end: 14
  },
  slide14: {
    preload: 12,
    start: 13,
    end: 15
  },
  /* skyCapsule: {
      preload: 12,
      start: 13.8,
      easeInLength: 0.5,
      easeOutLength: 1,
      end: 17,
      contentLength: 1.4
    }, */
  skyCapsule: {
    preload: 12,
    start: 13.8,
    end: 17
  },
  slide15: {
    preload: 13,
    start: 14,
    end: 16
  },
  slide16: {
    preload: 14,
    start: 15,
    end: 17
  },
  blueTable: {
    preload: 14,
    start: 16.5,
    end: 19.5
  },
  slide17: {
    preload: 15,
    start: 16,
    end: 18
  },
  cubes1: {
    preload: 14,
    start: 16.25,
    easeInLength: 0.75,
    easeOutLength: 0.75,
    end: 19,
    contentLength: 1
  },

  slide18: {
    preload: 16,
    start: 17,
    end: 19
  },
  slide19: {
    preload: 17,
    start: 18,
    end: 20
  },
  line5: {
    preload: 16,
    start: 18.6,
    end: 24.67
  },
  slide20: {
    preload: 18,
    start: 19,
    end: 21
  },
  verticalLines20: {
    preload: 18,
    start: 19,
    end: 21
  },
  slide21: {
    preload: 19,
    start: 20,
    end: 22
  },

  bluePrint: {
    preload: 19,
    start: 20.6,
    easeInLength: 0.75,
    easeOutLength: 0.75,
    end: 22.6,
    contentLength: 1
  },
  slide22: {
    preload: 20,
    start: 21,
    end: 23
  },
  greenChecker: {
    preload: 19,
    start: 21.5,
    easeInLength: 0.5,
    easeOutLength: 0.75,
    end: 23.5,
    contentLength: 1
  },
  slide23: {
    preload: 21,
    start: 22,
    end: 24
  },
  mixedChecker: {
    preload: 19,
    start: 22.5,
    easeInLength: 0.5,
    easeOutLength: 0.75,
    end: 25,
    contentLength: 1
  },
  cubes2: {
    preload: 19,
    start: 21.75,
    easeInLength: 0.75,
    easeOutLength: 1,
    end: 25,
    contentLength: 1
  },
  slide24: {
    preload: 22,
    start: 23,
    end: 25
  },
  slide25: {
    preload: 23,
    start: 24,
    end: 26
  },
  fireworks: {
    preload: 23,
    start: 24,
    end: 26
  }
}
