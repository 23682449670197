<template>
  <div class="bg-dark-blue w-100 h-100">
    <resize-observer @notify="onResize" :showTrigger="true" />
    <transition name="fade" mode="in-out">
      <div @wheel="handleSrollWheel" class="position-fixed w-100 h-100 overflow-hidden app" ref="app"
        v-show="inLandscape">
        <transition name="fade">
          <div v-if="globalUnits >= 1" style="left:2vw; top:3vh;" class="position-fixed z-9">
            <a class="pointer" :href="cornerLogoUrl">
              <inline-svg src="images/triumf-logo-corner.svg" width="10vw" aria-label="Go back to main website"
                class="shadow-sm"></inline-svg>
            </a>
          </div>
        </transition>
        <transition name="fade">
          <div style="z-index:9;right:2vw;top:3vh;transform:translate(0%, 0%);padding:0.20% 0.35%;"
            class=" position-fixed border-radius-0-75vw shadow-sm d-flex align-items-center justify-content-center bg-summer-sky "
            v-if="globalUnits >= 1">
            <p style="" class="my-0 font-vw-1-35 mx-2 text-white">
              {{ Math.round(globalUnits) }}/25
            </p>
          </div>
        </transition>
        <component v-for="slide in activeSlidesArray" :key="'slide-' + slide" :is="slide" class="position-absolute"
          :globalUnits="globalUnits" :slideIndex="slidesIndex[slide]" />
        <component v-for="slide in preloadSlidesArray" :key="'slide-' + slide" :is="slide" :globalUnits="globalUnits"
          :slideIndex="slidesIndex[slide]" style="visibility: hidden;" />
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div class="position-fixed w-100 h-100 overflow-hidden app z-2" v-show="!inLandscape">
        <portrait-alert class="position-absolute" />
      </div>
    </transition>
  </div>
</template>
<script>
import "vue3-resize/dist/vue3-resize.css"
import "@/assets/styles/global.scss"
import { defineAsyncComponent } from "vue"
import anime from "animejs"
import Hammer from "hammerjs"
/* globalUnits - start; */
import slidesIndex from "@/assets/slidesIndex"
export default {
  components: {
    slide0: defineAsyncComponent(() => import("@/components/Slides/Slide0")),
    slide1: defineAsyncComponent(() => import("@/components/Slides/Slide1")),
    slide2: defineAsyncComponent(() => import("@/components/Slides/Slide2")),
    slide3: defineAsyncComponent(() => import("@/components/Slides/Slide3")),
    slide4: defineAsyncComponent(() => import("@/components/Slides/Slide4")),
    slide5: defineAsyncComponent(() => import("@/components/Slides/Slide5")),
    slide6: defineAsyncComponent(() => import("@/components/Slides/Slide6")),
    slide7: defineAsyncComponent(() => import("@/components/Slides/Slide7")),
    slide8: defineAsyncComponent(() => import("@/components/Slides/Slide8")),
    slide9: defineAsyncComponent(() => import("@/components/Slides/Slide9")),
    slide10: defineAsyncComponent(() => import("@/components/Slides/Slide10")),
    slide11: defineAsyncComponent(() => import("@/components/Slides/Slide11")),
    slide12: defineAsyncComponent(() => import("@/components/Slides/Slide12")),
    slide13: defineAsyncComponent(() => import("@/components/Slides/Slide13")),
    slide14: defineAsyncComponent(() => import("@/components/Slides/Slide14")),
    slide15: defineAsyncComponent(() => import("@/components/Slides/Slide15")),
    slide16: defineAsyncComponent(() => import("@/components/Slides/Slide16")),
    slide17: defineAsyncComponent(() => import("@/components/Slides/Slide17")),
    slide18: defineAsyncComponent(() => import("@/components/Slides/Slide18")),
    slide19: defineAsyncComponent(() => import("@/components/Slides/Slide19")),
    slide20: defineAsyncComponent(() => import("@/components/Slides/Slide20")),
    slide21: defineAsyncComponent(() => import("@/components/Slides/Slide21")),
    slide22: defineAsyncComponent(() => import("@/components/Slides/Slide22")),
    slide23: defineAsyncComponent(() => import("@/components/Slides/Slide23")),
    slide24: defineAsyncComponent(() => import("@/components/Slides/Slide24")),
    slide25: defineAsyncComponent(() => import("@/components/Slides/Slide25")),
    cubes1: defineAsyncComponent(() => import("@/components/Cubes/Cubes1")),
    cubes2: defineAsyncComponent(() => import("@/components/Cubes/Cubes2")),
    sky1: defineAsyncComponent(() => import("@/components/Patterns/Sky1")),
    line1: defineAsyncComponent(() => import("@/components/Lines/Line1")),
    line2: defineAsyncComponent(() => import("@/components/Lines/Line2")),
    line3: defineAsyncComponent(() => import("@/components/Lines/Line3")),
    line4: defineAsyncComponent(() => import("@/components/Lines/Line4")),
    line5: defineAsyncComponent(() => import("@/components/Lines/Line5")),
    horizontalLinePattern: defineAsyncComponent(() =>
      import("@/components/Patterns/HorizontalLinePattern")
    ),
    horizontalDotPattern: defineAsyncComponent(() =>
      import("@/components/Patterns/HorizontalDotPattern")
    ),
    verticalLines12: defineAsyncComponent(() =>
      import("@/components/Patterns/VerticalLines12")
    ),
    verticalLines20: defineAsyncComponent(() =>
      import("@/components/Patterns/VerticalLines20")
    ),
    accelerationLines4: defineAsyncComponent(() =>
      import("@/components/AccelerationLines4")
    ),
    portraitAlert: defineAsyncComponent(() =>
      import("@/components/PortraitAlert")
    ),
    checkerBoardPattern: defineAsyncComponent(() =>
      import("@/components/Patterns/CheckerBoardPattern")
    ),
    skyCapsule: defineAsyncComponent(() =>
      import("@/components/Patterns/SkyCapsule")
    ),
    blueTable: defineAsyncComponent(() =>
      import("@/components/Patterns/BlueTable")
    ),
    bluePrint: defineAsyncComponent(() =>
      import("@/components/Patterns/BluePrint")
    ),
    greenChecker: defineAsyncComponent(() =>
      import("@/components/Patterns/GreenChecker")
    ),
    mixedChecker: defineAsyncComponent(() =>
      import("@/components/Patterns/MixedChecker")
    ),
    fireworks: defineAsyncComponent(() =>
      import("@/components/Patterns/Fireworks")
    )
  },
  created() {
    window.addEventListener("keyup", this.keyPress)
  },
  data() {
    return {
      globalUnits: 0,
      slidesIndex: slidesIndex,
      inTransition: false,
      inLandscape: false,
      cornerLogoUrl: process.env.VUE_APP_CORNER_LOGO_URL
    }
  },
  computed: {
    activeSlidesArray() {
      let indexKeys = Object.keys(this.slidesIndex)
      let activeSlides = indexKeys.filter((slide) => {
        return this.inRange(
          this.globalUnits,
          this.slidesIndex[slide].start,
          this.slidesIndex[slide].end
        )
      })
      return activeSlides
    },
    preloadSlidesArray() {
      let indexKeys = Object.keys(this.slidesIndex)
      let preloadSlides = indexKeys.filter((slide) => {
        return this.inRange(
          this.globalUnits,
          this.slidesIndex[slide].preload,
          this.slidesIndex[slide].start
        )
      })
      return preloadSlides
    }
  },
  methods: {
    handleSrollWheel(event) {
      event.preventDefault()
      if (this.inTransition == false) {
        this.inTransition = true
        //console.log("Handling Scroll Event")
        let finalValue
        if (Math.sign(event.deltaY) == -1 && this.globalUnits > 0) {
          finalValue = this.globalUnits - 1
        } else if (Math.sign(event.deltaY) == 1 && this.globalUnits < 25) {
          finalValue = this.globalUnits + 1
        }
        const config = {
          easing: "easeInOutQuad",
          duration: 2000
        }

        this.smoothUpdate(finalValue, config)
      }
    },
    smoothUpdate(end, config) {
      return anime({
        targets: this,
        globalUnits: end,
        easing: config.easing,
        duration: config.duration,
        round: 100,
        complete: () =>
          setTimeout(() => {
            this.inTransition = false
          }, 100)
      })
    },
    updateGlobalUnits(value) {
      this.globalUnits = value
      //console.log("IM BEING TRGGERD", value)
    },
    inRange(x, min, max) {
      return (x - min) * (x - max) <= 0
    },
    setHammer() {
      //let sky = document.querySelector(".sky")
      //let sky = document.getElementById("sky")
      let app = this.$refs.app

      //console.log("ESTO ENVIA", app.scrollWidth)
      var hammertime = new Hammer(app)
      hammertime.get("swipe").set({ direction: Hammer.DIRECTION_ALL })
      hammertime.on("swipe", (e) => {
        let jResult = ""
        switch (true) {
          // LEFT
          case e.angle < -157.5 || e.angle > 157.5:
            jResult = "left"
            break

          // RIGHT
          case e.angle > -22.5 && e.angle < 22.5:
            jResult = "right"
            break

          // UP
          case e.angle < -67.5 && e.angle > -112.5:
            jResult = "up"
            break

          // Down
          case e.angle > 67.5 && e.angle < 112.5:
            jResult = "down"
            break

          // LEFT-Up
          case e.angle < -112.5 && e.angle > -157.5:
            jResult = "left-Up"
            break

          // LEFT-Down
          case e.angle > 112.5 && e.angle < 157.5:
            jResult = "left-Down"
            break

          // RIGHT-Up
          case -22.5 > e.angle && e.angle > -67.5:
            jResult = "right-Up"
            break

          // RIGHT-Down
          case e.angle > 22.5 && e.angle < 67.5:
            jResult = "right-Down"
            break

          default:
            // code block
            jResult = "unknown" // UNKNOWN - what happened?
        }
        /* console.log("--00--> Swipe: " + e.direction + " -- Dir is: " + jResult) */

        if (this.inTransition == false) {
          this.inTransition = true
          let finalValue

          if (jResult == "left" && this.globalUnits < 25) {
            finalValue = this.globalUnits + 1
          } else if (jResult == "right" && this.globalUnits > 0) {
            finalValue = this.globalUnits - 1
          } /* else if (jResult == "down") {
            finalValue = 25
          } else if (jResult == "up") {
            finalValue = 1
          } */ else {
            this.inTransition == false
          }
          const config = {
            easing: "easeOutQuart",
            duration: 1500
          }

          this.smoothUpdate(finalValue, config)
        }
      })
      document.querySelector(".app").addEventListener(
        "touchmove",
        function (event) {
          //console.log("Empecé a mover con el fingui", event)
          event.preventDefault()
        }.bind(this)
      )
    },
    keyPress(event) {
      if (this.inTransition == false) {
        this.inTransition = true
        //console.log("espichaste", event)
        let finalValue

        /* if (event.key && !isNaN(event.key)) {
          //console.log("NUMBER")
          finalValue = Number(event.key)
        } else */ if (
          event.code == "ArrowLeft" &&
          this.globalUnits > 0
        ) {
          finalValue = this.globalUnits - 1
        } else if (event.code == "ArrowRight" && this.globalUnits < 25) {
          finalValue = this.globalUnits + 1
        } else if (event.code == "End") {
          finalValue = 25
        } else if (event.code == "Home") {
          finalValue = 0
        } else if (event.code == "PageUp" && this.globalUnits <= 22) {
          finalValue = this.globalUnits + 3
        } else if (event.code == "PageDown" && this.globalUnits >= 4) {
          finalValue = this.globalUnits - 3
        } else {
          this.inTransition = false
        }
        if (finalValue !== this.globalUnits) {
          const config = {
            easing: "easeOutQuart",
            duration: 1500
          }

          //console.log("espichaste y soy distinto", finalValue, event, config)
          this.smoothUpdate(finalValue, config)
        }
      }
    },
    onResize({ width, height }) {
      if (width > height && this.inLandscape == false) {
        this.inLandscape = true
      } else if (width < height && this.inLandscape == true) {
        this.inLandscape = false
      } else {
        this.inLandscape = true
      }
    }
  },
  beforeUnmount: function () { },
  mounted() {
    this.setHammer()
    this.onResize({ width: window.innerWidth, height: window.innerHeight })
  }
}
</script>

<style lang="scss">
.app {
  background: linear-gradient(0deg,
      rgb(30, 30, 66),
      rgb(35, 35, 88) 40%,
      rgb(35, 35, 88) 60%,
      rgb(30, 30, 66) 100%);
}
</style>
